
import { getProductDetail } from '@/api/product';
import { OrderModule } from '@/store/modules/order';
import { Vue, Component } from 'vue-property-decorator';
import { storageKey } from '@/enums/localStorage';
import { getOrderCount } from '@/api/order';

@Component({
  name: 'OrderDetail',
})

export default class extends Vue {
  private apiUrl = process.env.VUE_APP_BASE_API;

  private productId = this.$route.params.productId;

  private product: any = {};

  private loading = true;

  private selectedShop = JSON.parse((window as any).localStorage.getItem(storageKey.pickUpPlace));

  private shopStock = this.$route.params.shopStock;

  private stock = this.$route.params.stock;

  private count = Number(this.$route.params.cnt);

  private productCnt = Number(this.$route.params.cnt);
  // private count = 0;

  mounted() {
    this.getProductDetail();
    this.getOrderCount();
    console.log(OrderModule.orderForm);
  }

  private getOrderCount() {
    getOrderCount(this.selectedShop.idx, OrderModule.orderForm).then((res) => {
      console.log(res);
    });
  }

  private getProductDetail() {
    this.loading = true;
    getProductDetail(Number(this.productId)).then((res) => {
      this.loading = false;
      this.product = res.data;
    });
  }

  private handleConfirm() {
    if (Number(this.count) < 1) {
      this.$message.info('수량을 한개이상 선택해주세요.');
    } else if (Number(this.shopStock) < Number(this.count)) {
      this.$message.info('최대 구매 수량보다 많이 선택하실 수 없습니다.');
    // if (this.count > this.selectedShop.stock || this.count + OrderModule.orderForm.count > this.selectedShop.stock) {
      // this.$message.info(`최대 구매 수량보다 많이 선택하실 수 없습니다. 최대 구매 수량 : ${this.selectedShop.stock}개`);
    } else {
      if (Number(this.count) > 0) {
        OrderModule.addProduct({
          product: this.product,
          count: this.count,
        });
      }
      this.$router.push({
        name: 'Order',
      });
    }
  }
}
